.image-gallery-slide img {
    height: 60vmax;
}

.image-gallery-thumbnail img {
    height: 10vh;
    /* width: auto; */
    /* width: 1rem; */
}

.fullscreen .image-gallery-slide img {
    height: 85vmax;
}